export const defaultThemeColors = {
    primary: '#2442AF',
    secondary: '#6E759F',
    success: '#57CA22',
    warning: '#FFA319',
    error: '#FF1943',
    info: '#33C2FF',
    black: '#171717',
    white: '#ffffff',
};
